export const parseTermsAndConditions = (terms) => {
  let parsedTerms = terms;
  parsedTerms = parsedTerms.split("<li>");
  // remove header
  parsedTerms.shift();
  // clean up terms
  parsedTerms = parsedTerms.map( (term) => {
    term = term.replace("</li>", "");
    term = term.replace("</ul>", "");
    term = term.trim();

    return term;
  })
  return parsedTerms;
};
